import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

import favicon from '../images/16.png'
import logo from '../images/logo.svg'

function SEO({
  description,
  lang,
  meta,
  title,
  path,
  date,
  type,
  image,
  rating,
  noindex,
  canonicalUrl
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description

        const BlogUrl = `/${lang === 'ru' ? '' : 'en'}`
        const PostUrl = `${path}`
        const Phone = '(044) 3000 277'
        const context = 'http://schema.org'
        const reviewCount =
          (Math.floor(Math.random() * 8) + 1) * (+new Date() % 10)

        const htmlLang = lang === 'ru' ? 'ru' : 'en'

        let schemaOrgJSONLD = [
          {
            '@context': context,
            '@type': 'Organization',
            url: BlogUrl,
            name: data.site.siteMetadata.title,
            sameAs: ['https://t.me/pravda_casino'],
            telephone: Phone
          }
        ]

        if (path) {
          if (type === 'post') {
            schemaOrgJSONLD.push(
              {
                '@context': context,
                '@type': 'Article',
                datePublished: date,
                dateModified: date,
                mainEntityOfPage: {
                  '@type': 'WebPage',
                  '@id': BlogUrl
                },
                aggregateRating: {
                  '@type': 'aggregateRating',
                  ratingValue: rating,
                  bestRating: 5,
                  reviewCount: reviewCount === 0 ? 14 : reviewCount
                },
                url: PostUrl,
                about: title,
                author: data.site.siteMetadata.author,
                publisher: {
                  '@type': 'Organization',
                  name: data.site.siteMetadata.author,
                  telephone: Phone,
                  logo: {
                    '@type': 'ImageObject',
                    url: favicon,
                    contentUrl: logo,
                    width: 208,
                    height: 51
                  }
                },
                headline: title,
                inLanguage: lang,
                alternateName: title,
                image: {
                  '@type': 'ImageObject',
                  url: image
                }
              },
              {
                '@context': context,
                '@type': 'BreadcrumbList',
                itemListElement: [
                  {
                    '@type': 'ListItem',
                    position: 1,
                    item: {
                      '@id': BlogUrl,
                      url: BlogUrl,
                      image: favicon,
                      name: data.site.siteMetadata.author
                    }
                  },
                  {
                    '@type': 'ListItem',
                    position: 2,
                    item: {
                      '@id': PostUrl,
                      url: PostUrl,
                      image: favicon,
                      name: title
                    }
                  }
                ]
              }
            )
          } else {
            schemaOrgJSONLD.push({
              '@context': context,
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  item: {
                    '@id': BlogUrl,
                    url: BlogUrl,
                    image: favicon,
                    name: data.site.siteMetadata.author
                  }
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  item: {
                    '@id': PostUrl,
                    url: PostUrl,
                    image: favicon,
                    name: title
                  }
                }
              ]
            })
          }
        }

        return (
          <Helmet
            htmlAttributes={{
              lang: htmlLang
            }}
            title={title}
            titleTemplate={'%s | ' + data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: data.site.siteMetadata.author
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              }
            ].concat(meta)}
          >
            {noindex && <meta name='robots' content='noindex, nofollow' />}
            <link rel='canonical' href={canonicalUrl ? canonicalUrl : path} />
            <script type='application/ld+json'>
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'ru',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  rating: PropTypes.number,
  noindex: PropTypes.bool,
  canonicalUrl: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
